import { render, staticRenderFns } from "./Prospectos.vue?vue&type=template&id=b330b692"
import script from "./Prospectos.vue?vue&type=script&lang=js"
export * from "./Prospectos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports