<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat>
      <v-icon left>mdi-contacts-outline</v-icon>
      <v-toolbar-title>CONTACTOS</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <v-col cols="2">
        <v-select
          v-model="listaActual"
          :items="tipoLista"
          item-text="descripcion"
          item-value="lista_id"
        >
        </v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="blue" small dark @click="abrirCrearContactoDialog"
        ><v-icon>mdi-plus</v-icon>Crear contacto</v-btn
      >
    </v-toolbar>
    <v-toolbar flat>
      <v-col>
        <v-text-field
          clearable
          label="Código"
          v-model="busCodigo"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          clearable
          label="Nombres"
          v-model="busContacto"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          clearable
          label="Teléfono"
          v-model="busTelefono"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-select
          clearable
          label="Etapa del ciclo de vida"
          v-model="busEtapa"
          :items="estadosCv"
          item-text="description"
          item-value="status_type_id"
          @change="cargarLista"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Estado"
          clearable
          v-model="busEstado"
          :items="getEstadosCvFiltro"
          item-text="description"
          item-value="status_id"
          @change="cargarLista"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          clearable
          label="Asignado a"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          v-model="busAsignado"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>
    </v-toolbar>

    <v-data-table
      :headers="headersContactos"
      :items-per-page="itemsPerPage"
      :items="leads"
      class="elevation-1"
      small
      hide-default-footer
    >
      <template v-slot:item.party_id="{ item }">
        <v-btn
          text
          small
          title="Abrir contacto"
          :to="`/contacto_form/${item.party_id}`"
        >
          {{ item.party_id }}
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>

    <!-- DIALOG NUEVO CONTACTO -->
    <v-dialog v-model="dialogCrearContacto" persistent width="700">
      <v-form ref="formCrearContacto">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Crear nuevo contacto
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogCrearContacto = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosLead.first_name"
                label="Nombres"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                v-model="datosLead.last_name"
                label="Apellidos"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="validarTelefono"
                v-model="datosLead.telefono"
                label="Teléfono"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="validarEmail"
                v-model="datosLead.email"
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-select
                :rules="requiredRule"
                :items="fuentes"
                label="Fuente"
                item-text="description"
                item-value="data_source_id"
                v-model="datosLead.data_source_id"
              >
              </v-select>
            </v-col>

            <!-- <v-col md="6" class="py-0 my-0">
              <v-select
                :items="campanias"
                label="Campaña"
                item-text="campaign_name"
                item-value="marketing_campaign_id"
                v-model="datosLead.marketing_campaign_id"
              >
              </v-select>
            </v-col> -->

            <!-- <v-col md="6" class="py-0 my-0">
              <v-select
                :items="getAnuncios"
                label="Anuncio"
                item-text="descripcion"
                item-value="mk_anuncio_id"
                v-model="datosLead.mk_anuncio_id"
              >
              </v-select>
            </v-col> -->

            <v-col md="6" class="pt-0 my-0">
              <v-select
                :rules="requiredRule"
                :items="servicios"
                label="Servicio"
                item-text="nombre"
                item-value="servicio_crm_id"
                v-model="datosLead.servicio_lead_id"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarNuevoContacto">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogCrearContacto = false">
              <v-icon>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ContactoForm from "./ContactoForm";

export default {
  name: "ListaLeads",
  components: {
    ContactoForm,
  },
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    pageCount: 0,
    overlay: false,
    required: [(v) => !!v || "El campo es requerido"],

    tipoLista: [
      { lista_id: "LC_MISCONTACTOS", descripcion: "Mis contactos" },
      {
        lista_id: "LC_DIRECCIONES",
        descripcion: "Direcciones",
      },
    ],
    listaActual: "LC_MISCONTACTOS",
    headersContactos: [],
    headers: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombres", align: "start", value: "cliente_nombres" },
      { text: "Etapa Ciclo V.", align: "start", value: "estado_ciclo_vida" },
      { text: "Estado", align: "start", value: "estado" },
      { text: "Asignado a", align: "start", value: "created_by_user_login" },
    ],

    leads: [],

    dialogoLead: false,
    datosLead: {
      party_id: "",
      status_id: "",
      data_source_id: "",
      acepta_recordatorios: "",
      created_date: "",
      created_by_user_login: "",
      description: "",
      party_type_id: "",
      first_name: "",
      last_name: "",
      telefono: "",
      email: "",
      mk_anuncio_id: null,
      marketing_campaign_id: "",
    },
    busContacto: "",
    busCodigo: "",
    busEtapa: "",
    busEstado: "",
    busAsignado: "",
    busTelefono: "",
    opLead: [],
    eventosLead: [],
    cotizacionesLead: [],
    fuentes: [],
    campanias: [],
    anuncios: [],
    servicios: [],
    estados: [],
    estadosCv: [],
    dialogCrearContacto: false,
    overlay: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),

    validarTelefono() {
      if (this.datosLead.telefono == null) {
        return [true];
      }

      if (this.datosLead.telefono.match(/\D/)) {
        return ["Teléfono inválido"];
      }
      let phone = this.datosLead.telefono;
      if (phone != null) {
        if (!(phone.length == 10) && !(phone.length == 9))
          return ["Teléfono inválido"];
      }
    },

    validarEmail() {
      if (this.datosLead.email == "") {
        return [true];
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.datosLead.email
        )
      ) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    getEstadosCv() {
      let lista = [];
      for (let i = 0; i < this.estados.length; i++) {
        if (this.datosLead.estado_cv_id == this.estados[i].status_type_id) {
          lista.push(this.estados[i]);
        }
      }
      return lista;
    },

    getEstadosCvFiltro() {
      let lista = [];
      for (let i = 0; i < this.estados.length; i++) {
        if (this.busEtapa == this.estados[i].status_type_id) {
          lista.push(this.estados[i]);
        }
      }
      return lista;
    },

    getAnuncios() {
      let lista = [];
      for (let i = 0; i < this.anuncios.length; i++) {
        if (
          this.datosLead.marketing_campaign_id ==
          this.anuncios[i].marketing_campaign_id
        ) {
          lista.push(this.anuncios[i]);
        }
      }
      return lista;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarLista() {
      this.setLoadingTable(true);
      this.overlay = true;

      this.setUrl("lista-partys");

      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          typeList: "listaProspectos",
          party_id: this.busCodigo,
          nombres: this.busContacto,
          status_id: this.busEstado,
          etapa_cv_id: this.busEtapa,
          asignado_a: this.busAsignado,
          telefono: this.busTelefono,
          clientes: "pros",
          paginator: true,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.leads = res.data._embedded.lista_partys;
          this.pageCount = res.data.page_count;
          this.currentPage = res.data.page == 0 ? 1 : res.data.page;
          this.totalItems = res.data.total_items;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    /*abrirLeadDialog(leadId) {
      this.dialogoLead = true;
      this.cargarDatosLead(leadId);
    },*/

    abrirCrearContactoDialog() {
      this.dialogCrearContacto = true;
    },

    /* guardarLead() {
      if (!this.$refs.formLead.validate()) {
        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("lead");
      this.requestApi({
        method: "POST",
        data: this.datosLead,
      })
        .then((res) => {
          //console.log(res.data);
          this.cargarLista();
          this.dialogoLead = false;
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });

      // this.dialogCrearLead = false
    },

    cargarDatosLead(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("lista-partys/" + id);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosProspecto",
          party_id: id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosLead = res.data.party;
          this.opLead = res.data.oportunidades;
          this.eventosLead = res.data.eventos;
          this.fuentes = res.data.fuentes;
          this.campanias = res.data.campanias;
          this.servicios = res.data.servicios;
          this.anuncios = res.data.anuncios;
          this.estados = res.data.estados;
          this.estadosCv = res.data.estados_cv;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },*/
    cargarCampos() {
      this.setLoadingTable(true);

      this.setUrl("lead");

      this.requestApi({
        method: "GET",
        data: {
          view_type: "camposContactos",
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.headersContactos = res.data.detail.campos;
          this.estados = res.data.detail.estados;
          this.estadosCv = res.data.detail.estados_cv;
          this.fuentes = res.data.detail.fuentes;
          this.campanias = res.data.detail.campanias;
          this.servicios = res.data.detail.servicios;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarNuevoContacto() {
      if (!this.$refs.formCrearContacto.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("contacto");
      this.requestApi({
        method: "POST",
        accion: "crearContacto",
        data: this.datosLead,
      })
        .then((res) => {
          //this.abrirLeadDialog(res.data.detail.party_id);
          //this.dialogCrearLead = false;
          const routeData = this.$router.resolve({
            name: "contacto_form",
            query: {
              partyId: this.datosLead.party_id,
            },
          });
          //window.open(routeData.href, "_blank");
          //window.open(routeData.href);
          const pid = res.data.detail.party_id;
          this.$router.push({ path: `/contacto_form/${pid}` });

          //window.location.replace("lead_form/" + res.data.detail.party_id);
          //return;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange(value) {
      this.cargarLista();
    },
  },
  mounted() {
    this.cargarCampos();
    this.cargarLista();
    this.setTitleToolbar("CONTACTOS");
  },
};
</script>
